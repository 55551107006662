.modal-view {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 18px;
  padding-top: 75px;
  box-sizing: border-box;
  z-index: 1500;
  overflow: hidden;
  cursor: pointer;
  background: rgba(0,0,0,.5);
}
.modal-view
.MuiIconButton-colorSecondary {
  background-color: white;
}

.modal-view
.MuiIconButton-colorSecondary:hover {
  background-color: whitesmoke;
}

.modal-view-content {
  width: 100%;
  height: 100%;
}

.modal-view-close-button-container {
  position: absolute;
  top: 1em;
  right: 1em;
}

.modal-view-switch-mode-button {
  position: absolute;
  top: 5em;
  right: 1em;
}

.modal-view-make-standard-button {
  position: absolute;
  top: 9em;
  right: 1em;
}