.contact .overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.5);
  z-index: 1;
  cursor: pointer;
}

.contact .MuiCard-root
{
  z-index: 2;
	max-width: 50vw;
	max-height: 80vh;
	overflow-y: auto;
}

#root .contact {
	display: flex;
	align-items: center;
	justify-content: center;
}


@media screen and (max-width: 425px)
{
	.contact .MuiCard-root
	{
		max-width: 80vw;
	}
}