.custom-label-wrapper {
  display: flex;
  flex-direction: column;
}

.custom-label-main {
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.1px;
}

.custom-label-sub {
  font-size: 12px;
  color: #828282;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.custom-label-icon {
  height: 18px;
  width: 18px;
  color: #ffffff;
  background: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 9px;
}

.controls-raster {
  margin: 10px 0;
}
