.Pricing .pricingSlider span.MuiSlider-markLabel.MuiSlider-markLabelActive[data-index='0'] {
  color: rgba(0, 0, 0, 0.54);
  pointer-events: none;
}

.Pricing .accountTypesContainer .MuiCard-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Pricing .accountTypesContainer .MuiCard-root .MuiCardContent-root {
  height: 100%;
}

.Pricing .accountTypesContainer .pricingCardAction {
  justify-content: center;
  padding: 1.5rem;
  padding-top: 0.5rem;
}

#balanceContainer {
  height: 100%;
  width: 100%;
}

#balanceContainer nav {
  top: 0;
  z-index: 100;
}

#balanceContainer nav.MuiTab-textColorInherit.Mui-disabled {
  opacity: 0.25;
}

#balanceContainer .tabPagesContainer {
  padding: 24px;
  text-align: center;
}

#balanceContainer .tabPagesContainer h1.MuiTypography-h2 {
  margin: 2rem auto;
  margin-top: 2.5rem;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 2rem;
}

#balanceContainer .tabPagesContainer button.C2A {
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.pricingCardHeader {
  padding: 0;
}

.menu-tabs .MuiTabs-scrollable .MuiTabs-flexContainer {
  justify-content: center;
}

.menu-tabs .MuiTabs-scrollButtonsDesktop ~ .MuiTabs-scrollable .MuiTabs-flexContainer {
  justify-content: unset;
}

.menu-tabs .MuiTabs-scrollable .MuiTabs-flexContainer > * {
  flex-grow: 1;
}

.menu-tabs .MuiTabs-scrollButtonsDesktop ~ .MuiTabs-scrollable .MuiTabs-flexContainer button {
  flex-grow: unset;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  line-height: 36px;
  padding: 3px 5px 0 0;
}

.dialog-wrapper {
  letter-spacing: 0.4px;
  font-size: 12px;
  line-height: 16px;
  height: 750px;
}

.button-controls {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 15px 0;
}

.control-button-wrapper {
  width: 180px;
  height: 36px;
}

.dialog-actions-button {
  width: 96px;
  height: 36px;
}

.button-close {
  width: 62px;
}

.info-box {
  width: 100%;
  height: 48px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 4px 4px;
  color: #ffffff;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.info-circle {
  font-size: 20px;
  padding: 0 14px 0 18px;
}

.map-wrapper {
  position: relative;
}

.dialog-wrapper .leaflet-touch .leaflet-bar a:first-child {
  color: #1976d2;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 0;
  border-right: 1px solid #ccc;
}

.dialog-wrapper .leaflet-touch .leaflet-bar a:last-child {
  color: #1976d2;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 0;
}

.dialog-wrapper .leaflet-touch .leaflet-bar {
  border: none;
  display: flex;
}

.dialog-wrapper .leaflet-bar a,
.dialog-wrapper .leaflet-bar a:hover,
.dialog-wrapper .leaflet-bar a {
  border-bottom: none;
}

#GeoFence .map-wrap {
  height: 100%;
}

#GeoFence .map {
  position: absolute;
  width: 100%;
  height: 100%;
}

#GeoFence .MuiDialog-container .MuiDialog-paperScrollPaper {
  max-height: calc(100% - 120px);
  max-width: 70%;
}

@media only screen and (max-width: 1300px) {
  #GeoFence .MuiDialog-container .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 120px);
    /* max-width: calc(65% - 120px); */
  }
}
@media only screen and (max-width: 1180px) {
  #GeoFence .MuiDialog-container .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 120px);
    /* max-width: calc(75% - 120px); */
  }
}
@media only screen and (max-width: 600px) {
  #GeoFence .MuiDialog-container .MuiDialog-paperFullWidth {
    width: 100% !important;
  }
  #GeoFence .MuiDialog-container .MuiDialog-paper {
    margin: 0;
  }
  #GeoFence .MuiDialog-container .dialog-wrapper {
    padding: 8px 5px;
  }
  #GeoFence .MuiDialog-container .MuiDialog-paperScrollPaper {
    max-width: 100%;
  }
}
