.myTooltipClass * {
  font-family: 'Inter', 'Roboto', 'Arial', 'sans-serif';
  font-weight: 400;
}

.myTooltipClass .introjs-tooltiptext {
  hyphens: auto;
  font-size: 1rem;
  text-align: justify;
  line-height: 1.5;
}

.myTooltipClass .introjs-progressbar {
  background-color: #1976d2;
}

.myTooltipClass .introjs-button {
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 1rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Inter', 'Roboto', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Helvetica Neue', 'Arial',
    'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Exo 2';
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border: none;
  text-shadow: none;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.myTooltipClass .introjs-nextbutton {
  color: #fff;
  background-color: #1976d2;
}

.myTooltipClass .introjs-nextbutton:hover {
  background-color: #0d2e4c;
}

.myTooltipClass .introjs-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
}

.myTooltipClass .introjs-tooltip-header {
  padding-right: 52px;
  padding-top: 18px;
  position: relative;
}

.myTooltipClass .introjs-skipbutton {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 2;
  height: 32px;
  width: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myTooltipClass .introjs-skipbutton::before {
  content: '';
  display: block;
  background-color: rgba(0, 0, 0, 0.08);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 32px;
  opacity: 0;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.myTooltipClass .introjs-skipbutton:hover:before {
  opacity: 1;
}

.myTooltipClass .introjs-tooltip-title {
  font-family: 'Poppins', 'Roboto', 'Arial', 'sans-serif';
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.15;
  color: #0d2e4c;
  text-transform: capitalize;
}

.myTooltipClass .introjs-helperNumberLayer {
  padding-top: 0;
}
